<template>
  <div>
    <form @submit.prevent="onSubmitFilters">
      <gro-card :bordered="false">
        <div slot="body">
          <h2>Filter table</h2>
          <div class="mb-2">Narrow down the items shown in the table</div>

          <div class="mb-3" v-if="referralSources.length !== 1">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Referral source
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in referralSources" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3" v-if="subSourceList.length !== 1">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Sub source
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in subSourceList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.id"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="gro__input mb-2">
            <label class="gro__input__label">Eligibility status
            </label>
            <gro-radio v-model="model.eligible" label="Eligible" :value="true"
                       :input-value="true"></gro-radio>
            <gro-radio v-model="model.eligible" class="ml-3" :value="false" label="Not eligible"
                       :input-value="false"></gro-radio>
            <gro-radio v-model="model.eligible" class="ml-3" :value="''" label="All"
                       :input-value="false"></gro-radio>
          </div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Contact status
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in contactStatusList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Intervention completion status <i class="icon-info-circle" v-tooltip="{content: 'This field indicates whether the intervention was completed by the applicant.'}"></i>
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in completionStatusList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Referral status <i class="icon-info-circle" v-tooltip="{content: 'This field indicates the status of the referral. Use this to track who you’ve finished referring or discharged from a given intervention.'}"></i>
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in referralCompletionStatusList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Preferred method of contact
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in methodOfDeliveryList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">
                Has Gro account created?
              </label>
            </div>
            <div class="d-flex">
              <div class="d-inline-flex">
                <gro-radio v-model="model.groAccountCreated" :value="true" label="Yes"
                ></gro-radio>
              </div>
              <div class="d-inline-flex pl-3">
                <gro-radio v-model="model.groAccountCreated" :value="false" label="No"
                ></gro-radio>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">
                Is Postnatal?
              </label>
            </div>
            <div class="d-flex">
              <div class="d-inline-flex">
                <gro-radio v-model="model.postNatal" :value="true" label="Yes"
                ></gro-radio>
              </div>
              <div class="d-inline-flex pl-3">
                <gro-radio v-model="model.postNatal" :value="false" label="No"
                ></gro-radio>
              </div>
            </div>
          </div>


          <div class="divider mb-4"></div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Sex
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in genderList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <gro-input
                class="mb-1"
                placeholder="Post code"
                label="Applicant’s post code"
                id="txt_postcode"
                name="txt_postcode"
                type="text"
                v-model="model.postcode"
                >
            </gro-input>
            <div class="font-size-14">Tip: Separate multiple post codes with a comma</div>
          </div>

          <div class="gro__input mb-3">
            <label class="gro__input__label mb-2">Applicant age between </label>
            <div class="row">
              <div class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_minAge"
                    name="txt_minAge"
                    type="number"
                    placeholder="Age"
                    v-model="model.minAge">
                  <div slot="rightIcon">
                    years
                  </div>
                </gro-input>
              </div>
              <div style="margin: auto;">
                -
              </div>
              <div class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_maxAge"
                    name="txt_maxAge"
                    placeholder="Age"
                    type="number"
                    v-model="model.maxAge">
                  <div slot="rightIcon">
                    years
                  </div>
                </gro-input>
              </div>
            </div>
          </div>

          <div class="gro__input mb-3">
            <label class="gro__input__label mb-2">Applicant BMI between </label>
            <div class="row">
              <div class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_minBmi"
                    name="txt_minBmi"
                    type="number"
                    placeholder="BMI"
                    v-model="model.minBmi">
                </gro-input>
              </div>
              <div style="margin: auto;">
                -
              </div>
              <div class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_maxBmi"
                    name="txt_maxBmi"
                    placeholder="BMI"
                    type="number"
                    v-model="model.maxBmi">
                </gro-input>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Ethnicity</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.ethnicity"
                placeholder="Type to search for a ethnicity..."
                open-direction="bottom"
                :options="ethnicities.map(eth => eth.enumIndex)"
                :custom-label="opt => ethnicities.find(x => x.enumIndex == opt).label"
                :multiple="true"
                :clear-on-select="false"
                :hide-selected="true"
                >
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Preferred method of delivery
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in methodOfDeliveryList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Preferred weight management service
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in referralServicesList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-1">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Local services requested
              </label>
            </div>
            <div class="d-flex flex-wrap flex-column" style="height: 130px;">
              <div v-for="item, index in services" :key="`goal-${index}`" class="mb-2 mr-1">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                What are the applicant’s health improvement goals?
              </label>
            </div>
            <div class="d-flex flex-wrap flex-column" style="height: 110px;">
              <div v-for="item, index in goals" :key="`goal-${index}`" class="mb-2">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label mb-2">
                Requires support with learning difficulties or disabilities?
              </label>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <div v-for="item, index in requiresDisabilitySupportList" :key="`goal-${index}`" class="mb-2 mr-3">
                <gro-checkbox v-model="item.checked" :value="item.value"
                              :label="item.name"></gro-checkbox>
              </div>
            </div>
          </div>

          <div class="divider mb-4"></div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">
                Do you drink alcohol?
              </label>
            </div>
            <div class="d-flex">
              <div class="d-inline-flex">
                <gro-radio v-model="model.drinkAlcohol" :value="true" label="Yes"
                           ></gro-radio>
              </div>
              <div class="d-inline-flex pl-3">
                <gro-radio v-model="model.drinkAlcohol" :value="false" label="No"
                           ></gro-radio>
              </div>
            </div>
          </div>

          <div v-show="model.drinkAlcohol === true">
            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have a drink containing alcohol?</label>
              <select id="txt_alcoholHowOften" v-model="model.alcoholFrequency"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in alcoholFrequencyList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_standardDrinks" class="gro__input__label">How many standard drinks containing alcohol do you have on a typical day?</label>
              <select id="txt_standardDrinks" v-model="model.dailyCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in dailyAlcoholList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have six or more drinks on one occasion?</label>
              <select id="alcoholHowOftenSixOrMore" v-model="model.overSixCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option value="Daily" label="Daily or almost daily">Daily or almost daily</option>
                <option value="Weekly" label="Weekly">Weekly</option>
                <option value="Monthly" label="Monthly">Monthly</option>
                <option value="LessThanMonthly" label="Less than monthly">Less than monthly</option>
                <option value="Never" label="Never">Never</option>
              </select>
            </div>
          </div>


          <div class="divider mb-4"></div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Applicant's GP surgery</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="surgery"
                id="ajax3"
                label="name"
                track-by="name"
                placeholder="Type to search for a surgery..."
                open-direction="bottom"
                :options="surgeries"
                :multiple="false"
                :searchable="true"
                :loading="isSurgeriesLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindSurgeries">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedSurgery"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Health conditions</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.conditions"
                id="ajax"
                label="name"
                track-by="name"
                placeholder="Type to search for a condition..."
                open-direction="bottom"
                :options="conditions"
                :multiple="true"
                :searchable="true"
                :loading="isConditionsLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindConditions">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedConditions"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Medications</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.medications"
                id="ajax2"
                label="name"
                track-by="name"
                placeholder="Type to search for a medication..."
                open-direction="bottom"
                :options="medications"
                :multiple="true"
                :searchable="true"
                :loading="isMedicationsLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindMedications">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedMedications"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

              <gro-button
                  :disabled="isProcessing"
                  :loading="isProcessing"
                  loading-title="Processing..."
                  style="position: relative; z-index: 0"
                  class="w-100"
                  type="submit"
                  size="md"
                  gradient
                  glowing
                  centered>
                Submit
              </gro-button>
        </div>
      </gro-card>
    </form>
  </div>
</template>

<script>
import referralClient from "@/helpers/referralClient";
import {validationMixin} from 'vuelidate'
import conditionsMultiSelectMixin from '@/mixins/conditionsMultiSelectMixin'
import medicationsMultiSelectMixin from '@/mixins/medicationsMultiSelectMixin'
import surgeriesSelectMixin from "@/mixins/surgeriesSelectMixin"
import moment from "moment";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

const minAge = function(value) {
  let minAge = 18;
  let now = moment();
  let dobMoment = moment(value);
  let differenceInYears = now.diff(dobMoment, 'years');
  return differenceInYears >= minAge & differenceInYears < 120;
}

export default {
  name: "FilterList",
  mixins: [validationMixin, conditionsMultiSelectMixin, medicationsMultiSelectMixin, surgeriesSelectMixin],
  components: {Multiselect},
  props: ['ethnicities', 'goals', 'dateFrom', 'dateTo', 'referralServicesList', 'services', 'alcoholFrequencyList', 'dailyAlcoholList', 'referralSources', 'filters'],
  data() {
    return {
      isProcessing: false,
      surgery: null,
      model: {
        interventionCompleteStatus: [],
        referralCompletionStatus: [],
        eligible: null,
        contactStatus: [],
        gender: [],
        postcode: null,
        minAge: null,
        maxAge: null,
        minBmi: null,
        maxBmi: null,
        ethnicity: [],
        preferredContact: [],
        referralServices: [],
        conditions: [],
        medications: [],
        goals: [],
        services: [],
        requireDisabilitySupport: null,
        drinkAlcohol: null,
        alcoholFrequency: null,
        dailyCountAlcohol: null,
        overSixCountAlcohol: null,
        GPOrganisationCode: null,
        source: null,
        postNatal: null,
        subSourceIds: null,
      },
      contactStatusList: [
        {
          name: 'Not contacted',
          value: 'NotContacted',
          checked: false,
        },
        {
          name: 'Contacted',
          value: 'Contacted',
          checked: false,
        },
        {
          name: 'Did not respond',
          value: 'NoResponse',
          checked: false,
        },
      ],
      genderList: [
        {
          name: 'Male',
          value: 'Male',
          checked: false,
        },
        {
          name: 'Female',
          value: 'Female',
          checked: false,
        },
        {
          name: 'Other',
          value: 'Other',
          checked: false,
        },
      ],
      methodOfDeliveryList: [
        {
          name: 'Digital',
          value: 'Digital',
          checked: false,
        },
        {
          name: 'Face-to-face',
          value: 'FacetoFace',
          checked: false,
        },
        {
          name: 'Digital & Face to face',
          value: 'DigitalFaceToFace',
          checked: false,
        },
        {
          name: 'Offline',
          value: 'Offline',
          checked: false,
        },
      ],
      completionStatusList: [
        {
          name: 'Not complete',
          value: false,
          checked: false,
        },
        {
          name: 'Complete',
          value: true,
          checked: false,
        },
      ],
      referralCompletionStatusList: [
        {
          name: 'Not complete',
          value: 'NotComplete',
          checked: false,
        },
        {
          name: 'Complete',
          value: 'Complete',
          checked: false,
        },
        {
          name: 'Discharged',
          value: 'Discharged',
          checked: false,
        },
      ],
      requiresDisabilitySupportList: [
        {
          name: 'Yes',
          value: true,
          checked: false,
        },
        {
          name: 'No',
          value: false,
          checked: false,
        },
      ],
      subSourceList: []
    }
  },
  watch: {
    requiresDisabilitySupportList: {
      handler() {
        this.model.requireDisabilitySupport = this.requiresDisabilitySupportList
            .filter(x => x.checked === true)
            .map(x => (x.value))
      },
      deep: true
    },
    referralCompletionStatusList: {
      handler() {
        this.model.referralCompletionStatus = this.referralCompletionStatusList
            .filter(x => x.checked === true)
            .map(x => (x.value))
      },
      deep: true
    },
    completionStatusList: {
      handler() {
        this.model.interventionCompleteStatus = this.completionStatusList
            .filter(x => x.checked === true)
            .map(x => (x.value))
      },
      deep: true
    },
    goals: {
      handler() {
        this.model.goals = this.goals
            .filter(x => x.checked === true)
            .map(x => (x.goalId))
      },
      deep: true
    },
    contactStatusList: {
      handler() {
        this.model.contactStatus = this.contactStatusList
            .filter(x => x.checked === true)
            .map(x => (x.value))
      },
      deep: true
    },
    genderList: {
      handler() {
        this.model.gender = this.genderList
            .filter(x => x.checked === true)
            .map(x => (x.name))
      },
      deep: true
    },
    methodOfDeliveryList: {
      handler() {
        this.model.preferredContact = this.methodOfDeliveryList
            .filter(x => x.checked === true)
            .map(x => (x.value))
      },
      deep: true
    },
    referralServicesList: {
      handler() {
        this.model.referralServices = this.referralServicesList
            .filter(x => x.checked === true)
            .map(x => (x.id))
      },
      deep: true
    },
    referralSources: {
      handler() {
        this.model.source = this.referralSources
            .filter(x => x.checked === true)
            .map(x => (x.name))
      },
      deep: true
    },
    subSourceList: {
      handler() {
        this.model.subSourceIds = this.subSourceList
            .filter(x => x.checked === true)
            .map(x => (x.id))
      },
      deep: true
    },
    services: {
      handler() {
        this.model.services = this.services
            .filter(x => x.checked === true)
            .map(x => (x.id))
      },
      deep: true
    },
  },
  mounted() {
    const subSourcesArray = this.referralSources.flatMap(item =>
        item.subSources.map(sub => ({
          id: sub.id,
          name: `${item.name} - ${sub.name}`,
          checked: false,
        }))
    );
    this.subSourceList = subSourcesArray;
    if (this.filters.subSourceIds) {
      this.filters.subSourceIds.forEach((setFilter) => {
        this.subSourceList.forEach((modalFilter) => {
          if (modalFilter.id === setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.source) {
      this.filters.source.forEach((setFilter) => {
        this.referralSources.forEach((modalFilter) => {
          if (modalFilter.name === setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

      this.model.eligible = this.filters.eligible

    if (this.filters.contactStatus) {
      this.filters.contactStatus.forEach((setFilter) => {
        this.contactStatusList.forEach((modalFilter) => {
          if (modalFilter.value == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.interventionCompleteStatus) {
      this.filters.interventionCompleteStatus.forEach((setFilter) => {
        this.completionStatusList.forEach((modalFilter) => {
          if (modalFilter.value == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.gender) {
      this.filters.gender.forEach((setFilter) => {
        this.genderList.forEach((modalFilter) => {
          if (modalFilter.value == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.postcode) {
      this.model.postcode = this.filters.postcode;
    }

    if (this.filters.minAge) {
      this.model.minAge = this.filters.minAge;
    }

    if (this.filters.maxAge) {
      this.model.maxAge = this.filters.maxAge
    }

    if (this.filters.minBmi) {
      this.model.minBmi = this.filters.minBmi;
    }

    if (this.filters.maxBmi) {
      this.model.maxBmi = this.filters.maxBmi
    }

    // if (this.filters.ethnicity) {
    //   this.filters.ethnicity.forEach((setFilter) => {
    //     this.genderList.forEach((modalFilter) => {
    //       if (modalFilter.enumIndex == setFilter) {
    //         modalFilter.checked = true
    //       }
    //     })
    //   })
    // }

    if (this.filters.preferredContact) {
      this.filters.preferredContact.forEach((setFilter) => {
        this.methodOfDeliveryList.forEach((modalFilter) => {
          if (modalFilter.value == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.referralServices) {
      this.filters.referralServices.forEach((setFilter) => {
        this.referralServicesList.forEach((modalFilter) => {
          if (modalFilter.name == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.services) {
      this.filters.services.forEach((setFilter) => {
        this.services.forEach((modalFilter) => {
          if (modalFilter.name == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.goals) {
      this.filters.goals.forEach((patientGoal) => {
        this.goalsList.forEach((item) => {
          if (item.name === patientGoal.name) {
            item.checked = true;
          }
        })
      })
    }


    if (this.filters.requireDisabilitySupport) {
      this.filters.requireDisabilitySupport.forEach((setFilter) => {
        this.requiresDisabilitySupportList.forEach((modalFilter) => {
          if (modalFilter.value == setFilter) {
            modalFilter.checked = true
          }
        })
      })
    }

    if (this.filters.drinkAlcohol) {
      this.model.drinkAlcohol = this.filters.drinkAlcohol;
    }
    if (this.filters.alcoholFrequency) {
      this.model.alcoholFrequency = this.filters.alcoholFrequency;
    }
    if (this.filters.dailyCountAlcohol) {
      this.model.dailyCountAlcohol = this.filters.dailyCountAlcohol;
    }
    if (this.filters.overSixCountAlcohol) {
      this.model.overSixCountAlcohol = this.filters.overSixCountAlcohol;
    }
    if (this.filters.groAccountCreated ){
      this.model.groAccountCreated = this.filters.groAccountCreated;
    }

    if (this.filters.postNatal ){
      this.model.postNatal = this.filters.postNatal;
    }
    // if (this.filters.GPOrganisationCode) {
    //   this.model.GPOrganisationCode = this.filters.GPOrganisationCode;
    // }
    // if (this.filters.conditions) {
    //   this.model.conditions = this.filters.conditions;
    // }
    // if (this.filters.medications) {
    //   this.model.medications = this.filters.medications;
    // }
  },
  methods: {
    onSubmitFilters() {
      this.isProcessing = true;
      this.model.medications = this.model.medications
          .map(x => x.medicationId)

      this.model.conditions = this.model.conditions
          .map(x => x.conditionId)

      if (this.surgery) {
        this.model.GPOrganisationCode = this.surgery.organisationCode;
      }

      let filters = {
        ...this.model,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: 1,
        perPage: 10,
      }

      for (const key of Object.keys(filters)) {
        if (key !== 'eligible') {
          if (filters[key] === '' || filters[key] === null || filters[key].length === 0) {
            delete filters[key];
          }
        }
      }
      this.$store.commit('User/setFilters', filters);
      this.$emit('filtered', filters);
    }
  }
}
</script>
<style scoped>
.icon-info-circle {
  color: #c8c7cc;
  font-size: 12px;
}
</style>