<template>
  <div>
    <gro-card class="mb-5">
      <div slot="body">
        <gro-breadcrum>
          <gro-breadcrum-item
              :to="{name: 'portal.dashboard'}"
              aria-label="Title"
              title="Dashboard">
          </gro-breadcrum-item>
          <gro-breadcrum-item
              icon="arrow_right"
              material
              title="Referrals">
          </gro-breadcrum-item>
        </gro-breadcrum>
      </div>
    </gro-card>

    <div v-if="isLoadingReferrals" class="spinner__wrap">
      <gro-spinner size="lg" color="blue"></gro-spinner>
    </div>

    <div v-if="!isLoadingReferrals" class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h1>Referrals</h1>
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <gro-popper :config="{placement: 'right-start',  modifiers: {
            preventOverflow: {
              enabled: true,
              padding: 20
            },
            offset: {
              offset: '0, 16',
            }
          }}" style="left: 16px; top: 16px">
              <gro-outside-click slot-scope="{isOpen, open, close, update}" :do="close">
                <div>
                  <div data-trigger @click="update" class="d-inline">
                    <div class="d-flex">
                      <gro-button @click.native="open" class="circular-std-book">
                        <i class="icon icon-calendar mr-1" style="vertical-align: middle;"></i>
                        Date range
                      </gro-button>
                    </div>
                  </div>
                  <div v-show="isOpen" data-popper class="gro__calendar__popper" style="z-index: 999;">
                    <gro-card>
                      <div slot="body">
                        <div class="d-flex">
                          <div class="mr-4">
                            <div class="mb-2">From date</div>
                            <gro-single-date-picker
                                :maxDate="dateTo"
                                @input="loadData()"
                                v-model="dateFrom">
                            </gro-single-date-picker>
                          </div>
                          <div>
                            <div class="mb-2">To date</div>
                            <gro-single-date-picker
                                :maxDate="todaysDate"
                                @input="loadData()"
                                v-model="dateTo">
                            </gro-single-date-picker>
                          </div>
                        </div>
                      </div>
                    </gro-card>
                  </div>
                </div>
              </gro-outside-click>
            </gro-popper>
          </div>
          <gro-button class="mr-2" loading-title="Exporting data..." :loading="isLoadingExport" :disabled="isLoadingExport" @click="exportData()">
            <i class="icon material-icons mr-1" style="vertical-align: middle;">
              <svg width="21" height="21" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_418_901" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                  <path d="M20 0H0V20H20V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_418_901)">
                  <path d="M16.2222 17.5999H3.77777C3.56947 17.5999 3.39999 17.4205 3.39999 17.1999C3.39999 16.0138 3.40144 14.3421 3.4052 13.5999H3.41011V13.1999C3.41011 13.1535 3.41095 13.1013 3.42095 13.0704L4.97651 8.27041C5.02205 8.12957 5.13644 8.02907 5.2715 8.0053C5.34064 8.11399 5.41709 8.21108 5.50231 8.29873L5.9922 8.7999H5.89411H5.60327L5.51359 9.07657L4.21704 13.0766L4.04741 13.5999H4.59755H15.4032H15.9534L15.7837 13.0766L14.4872 9.07657L14.3975 8.7999H14.1067H14.0081L14.497 8.29941C14.5819 8.21209 14.6584 8.1148 14.7281 8.00522C14.8633 8.02885 14.9779 8.12939 15.0234 8.27014L16.579 13.0704C16.589 13.1013 16.5899 13.1534 16.5899 13.1999V13.5999H16.5948C16.5985 14.3421 16.6 16.0138 16.6 17.1999C16.6 17.4205 16.4305 17.5999 16.2222 17.5999ZM9.99999 10.6276L6.62505 7.17471C6.47467 7.02003 6.47467 6.75737 6.62579 6.60193C6.69794 6.52812 6.78917 6.4887 6.88888 6.4887C6.9886 6.4887 7.07983 6.52812 7.15272 6.60269L8.93542 8.43633L9.62222 9.14276V8.1575V2.7999C9.62222 2.57934 9.79169 2.3999 9.99999 2.3999C10.2083 2.3999 10.3778 2.57934 10.3778 2.7999V8.1575V9.14276L11.0646 8.43633L12.848 6.60193C12.9131 6.53533 13.0093 6.4967 13.1111 6.4967C13.2129 6.4967 13.3091 6.53533 13.3749 6.60269C13.5255 6.75758 13.5264 7.01933 13.3761 7.1735L9.99999 10.6276Z" fill="#062940"/>
                  <path d="M3.80368 14C3.80106 14.8518 3.8 16.2326 3.8 17.2H16.2C16.2 16.2326 16.1989 14.8518 16.1963 14H3.80368ZM10 2C10.4293 2 10.7778 2.3584 10.7778 2.8V8.1576L12.5612 6.3232C12.8552 6.02239 13.367 6.02241 13.661 6.3232C13.9643 6.6352 13.9643 7.1432 13.6626 7.4528L10 11.2L6.339 7.4544C6.03567 7.1424 6.03567 6.6352 6.339 6.3232C6.486 6.1728 6.68122 6.0888 6.88889 6.0888C7.09656 6.0888 7.29178 6.1728 7.43878 6.3232L9.22222 8.1576V2.8C9.22222 2.3584 9.57067 2 10 2ZM5.33334 7.6H5.50366C5.576 7.7504 5.667 7.8944 5.78911 8.02L6.94256 9.2H5.89411L4.59756 13.2H15.4032L14.1067 9.2H13.0582L14.2109 8.02C14.333 7.8944 14.4232 7.7504 14.4963 7.6H14.6667C15.0019 7.6 15.2982 7.82 15.404 8.1472L16.9596 12.9472C16.9868 13.0312 16.9899 13.116 16.9899 13.2C17 13.2 17 17.2 17 17.2C17 17.6424 16.6523 18 16.2222 18H3.77778C3.34766 18 3 17.6424 3 17.2C3 17.2 3 13.2 3.01011 13.2C3.01011 13.116 3.01322 13.0312 3.04045 12.9472L4.596 8.1472C4.70178 7.82 4.99811 7.6 5.33334 7.6Z" fill="#062940"/>
                </g>
              </svg>
            </i>
            Export data
          </gro-button>
          <gro-button @click="openUploadModal" class="circular-std-book">
            <i  class="icon material-icons" style="    vertical-align: middle;">upload</i>
            Upload data
          </gro-button>
        </div>
      </div>

      <h2 class="mb-3">Changes in date range</h2>

      <gro-card class="mb-4" v-if="dateFrom !== null || filtersLength >= 4">
        <div slot="body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <i style="vertical-align: sub;" class="icon icon-calendar"></i> <span style="font-weight:bold;font-size: 1rem;">Custom date range: <span style="font-weight:normal;">{{this.dateFrom ? formatDate(this.dateFrom) + ' -' : ''}} {{formatDate(this.dateTo)}}</span></span>
            </div>
            <div class="d-flex justify-content-between align-items-center clearDates" v-if="dateFrom !== null  || filtersLength >= 4" @click="loadData(true)">
              <i style="vertical-align: sub;" class="icon material-icons">close</i> <span>Clear filters</span>
            </div>
          </div>
        </div>
      </gro-card>



      <div class="d-flex enrolled">
        <gro-card class="w-100 mr-2 referral-card relative" :border="false">
          <div slot="body">
            <p class="number">{{ paginationInfo.totalCount }}</p>
            <p style="font-size: 1rem;">New patients enrolled within date range</p>
          </div>
        </gro-card>
        <gro-card class="w-100 referral-card relative" :border="false">
          <div slot="body">
            <p class="number">{{ counts.totalLeadSum }}</p>
            <p style="font-size: 1rem;">Total patients enrolled</p>
          </div>
        </gro-card>
      </div>

      <div class="patients mt-4 mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <h1>Patients</h1>
          <div class="d-flex align-items-center">
            <gro-button class="mr-2" @click="openFilterListModal()">
              <i  class="icon material-icons">filter_list</i>
              Filter list
            </gro-button>
            <gro-button class="mr-2" @click="openAddPatientModal()">
              <i  class="icon material-icons">add</i>
              Add new
            </gro-button>
          </div>
        </div>


        <div class="mt-4 mb-4">
          <form @submit.prevent="searchBarLookup">
            <div class="d-flex">
              <div class="d-inline-flex w-100">
                <gro-input
                    placeholder="Search for a patient"
                    class="w-100 searchBar"
                    id="txt_search"
                    name="txt_search"
                    type="text"
                    v-model="searchInput">
                  <i slot="leftIcon" class="icon text-light-grey icon-search"></i>
                </gro-input>
              </div>
              <div class="d-inline-flex pl-2">
                <gro-button type="submit">Search</gro-button>
              </div>
            </div>
          </form>
        </div>

        <div class="patients-table mt-3">
          <div v-if="isLoadingData" class="spinner__wrap pt-3 pb-3">
            <gro-spinner size="lg" color="blue"></gro-spinner>
          </div>
          <table v-if="!isLoadingData" class="gro__table w-100" style="overflow: hidden;background: #FFF;box-shadow: 0 0 0 1px #ebebf0;">
            <thead style="background: #fafafb;">
            <tr>
                <td class="circular-std-bold">ID</td>
                <td class="circular-std-bold">First name
                  <i  class="icon material-icons ml-2" style="font-size:18px;" @click="filterAlphabetically()" v-tooltip.top="{content: `<div class='tooltip-content'>Filter list alphabetically.</div>`}" >filter_list</i>
                </td>
                <td class="circular-std-bold">Last name</td>
                <td class="circular-std-bold">Age</td>
                <td class="circular-std-bold">BMI</td>
                <td class="circular-std-bold">Eligible?</td>
                <td class="circular-std-bold">Contacted?</td>
                <td class="circular-std-bold">Referral source</td>
                <td class="circular-std-bold">Signed up to Gro</td>
                <td v-if="isSuperUser"></td>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in referralData" :class="{'duplicate': item.isDuplicate && isSuperUser}" :key="'receipts-' + index">
                <td @click="goToPatient(item.id)" class="table-id">
                  {{ item.id }}
                </td>
                <td @click="goToPatient(item.id)" class="circular-std-bold">
                  {{ item.name.split(/\s(.+)/)[0] }}
                </td>
                <td @click="goToPatient(item.id)" class="circular-std-bold">
                  {{ item.name.split(/\s(.+)/)[1] }}
                </td>
                <td @click="goToPatient(item.id)">
                  {{item.age}}
                </td>
                <td @click="goToPatient(item.id)">
                <span class="bmi"
                      :class="{
                  'yellow-bmi': item.bmi.toFixed(1) < 18.5,
                  'green-bmi': item.bmi.toFixed(1) >= 18.5 && item.bmi.toFixed(1) <= 24.9,
                  'red-bmi': item.bmi.toFixed(1) >= 25}"
                >
                  {{item.bmi.toFixed(1)}}
                </span>
                </td>
                <td @click="goToPatient(item.id)">
                  {{item.eligible}}
                </td>
                <td @click="goToPatient(item.id)">
                  {{item.contacted}}
                  <i v-if="item.contactNote" v-tooltip.top="{content: `<div class='tooltip-content'>${item.contactNote}</div>`}" class="icon-info-circle"></i>
                </td>
                <td @click="goToPatient(item.id)">
                  {{item.referralSource ? item.referralSource : 'N/A'}}
                </td>
                <td @click="goToPatient(item.id)">
                  <div class="d-flex align-items-center" :class="{'opacity-lower': item.completed === 'Declined'}">
                    {{ item.groAccountCreated === '' ? 'No' : item.groAccountCreated }}
                    <i v-if="item.isDuplicate && isSuperUser" v-tooltip.top="{content: `<div class='tooltip-content'>This is a duplicate entry</div>`}" class="icon material-icons ml-2" style="color: #fc9202;font-size: 20px;">info</i>
                  </div>
                </td>
                <td v-if="isSuperUser">
                  <div class="d-inline-flex ml-auto">
                    <!-- action context menu here -->
                    <gro-icon-button-action-menu :config="{
                        placement: 'bottom',
                      }">
                      <div slot="icon">
                        <i class="material-icons">more_vert</i>
                      </div>
                      <div slot="actions">
                        <gro-action-menu-item style="color:#ff394a;" @click.native="openDeleteModal(item.id, index)">
                          <div slot="leftIcon">
                            <i class="material-icons text-danger">delete</i>
                          </div>
                          Delete
                        </gro-action-menu-item>
                      </div>
                    </gro-icon-button-action-menu>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="paginationInfo.totalCount">
              <tr>
                <td colspan="7">
                  <div class="d-flex align-content-center justify-content-center">
                    <div class="d-inline-flex mt-1 mr-3 font-size-14">Page {{paginationInfo.page}} of {{totalPageCount}}</div>
                    <gro-paginator
                        class="d-inline-flex"
                        v-model="paginationInfo.page"
                        :page-count="totalPageCount"
                        :force-page="paginationInfo.page"
                        :next-text="'\u276f'"
                        :prev-text="'\u276e'"
                        :page-class="'gro__paginator__page'"
                        :click-handler="onPageChanged">
                    </gro-paginator>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="mb-3 mt-4 d-flex justify-content-center" v-if="referralData.length === 0">
            No available data
          </div>
        </div>
      </div>
    </div>

    <gro-modal scrollable :is-open="isAddingPatient" width="640px" @close="confirmCloseAddPatient">
      <gro-card>
        <div slot="body">
          <add-new-patient
              :ethnicities="$store.getters['User/getEthnicities']"
              :goals="goals"
              :referralServicesList="referralServicesList"
              :services="services"
              :alcoholFrequencyList="alcoholFrequencyList"
              :dailyAlcoholList="dailyAlcoholList"
              :referral-sources="referralSources"
              @success="addNewPatient"
              @close="confirmCloseAddPatient">
          </add-new-patient>
        </div>
      </gro-card>
    </gro-modal>

    <gro-modal scrollable :is-open="isAddingFilters" width="640px" @close="confirmCloseFilters">
      <gro-card>
        <div slot="body">
          <filter-list
              :referral-sources="referralSources"
              :ethnicities="$store.getters['User/getEthnicities']"
              :goals="goals"
              :date-from="dateFrom"
              :date-to="dateTo"
              :referralServicesList="referralServicesList"
              :services="services"
              :alcoholFrequencyList="alcoholFrequencyList"
              :dailyAlcoholList="dailyAlcoholList"
              :filters="filters"
              @filtered="filterData"
              @close="confirmCloseFilters">
          </filter-list>
        </div>
      </gro-card>
    </gro-modal>

    <gro-modal :is-open="isAddingFile" width="640px" height="auto" @close="closeImportModal">
      <gro-card>
        <div slot="body">
          <h2 class="mb-2">Upload a file</h2>
          <p class="mb-3">Upload a CSV file to bulk import data from outside sources.</p>
          <FileUpload :sources="subReferralSources" :services="referralServicesList" @close="closeImportModal"></FileUpload>
        </div>
      </gro-card>
    </gro-modal>

    <gro-modal :is-open="isDeletingPatient" height="auto" @close="isDeletingPatient = false">
      <gro-card>
        <div slot="body">
          <h2 class="mb-3">Confirm deletion</h2>
          <p class="mb-3">Once delete, you will not be able to recover this entry.</p>
          <div class="d-flex align-items-center justify-content-end">
            <gro-button @click="isDeletingPatient = false">Cancel</gro-button>
            <gro-button fill fill-color="#ff394a" class="ml-2 delete_confirm" @click="deleteLead">Delete</gro-button>
          </div>
        </div>
      </gro-card>
    </gro-modal>
  </div>
</template>
<script>
import {referralClient} from "@/helpers/referralClient";
import AddNewPatient from "@/views/Portal/_components/AddNewPatient";
import FilterList from "@/views/Portal/_components/FilterList"
import FileUpload from "@/views/Portal/_components/FileUpload";
import moment from "moment";
import Qs from "qs";
import store from "@/store";

export default {
  components: {AddNewPatient, FilterList, FileUpload},
  data() {
    return {
      searchInput: '',
      referralData: null,
      paginationInfo: {
        totalCount: null,
        page: null,
        perPage: null,
      },
      isAddingPatient: false,
      isAddingFilters: false,
      ethnicities: null,
      goals: [],
      dateFrom: null,
      dateTo: moment().toDate(),
      filters: null,
      isCondensed: false,
      referralServicesList: [],
      isLoadingReferrals: false,
      counts: null,
      services: null,
      todaysDate: moment().toDate(),
      isLoadingData: false,
      isLoadingExport: false,
      fileToUpload: false,
      isAddingFile: false,
      alcoholFrequencyList: null,
      dailyAlcoholList: null,
      filterFirstName: false,
      referralSources: null,
      searchBy: 'name',
      isDeletingPatient: false,
      tempStoreUserDeleting: {
        index: null,
        id: null,
      },
      subReferralSources: null,
    }
  },
  async mounted() {
      this.isLoadingReferrals = true;
      const referralServices = await referralClient.get('/Reference/referralservices');
      const servicesData = await referralClient.get('/Reference/services');
      const referralSources =  await referralClient.get('/reference/sources');
      if (referralSources.data) {
        this.referralSources = referralSources.data.map(obj => ({...obj, checked: false}));
        this.subReferralSources = referralSources.data
            .filter(item => item.subSources.length > 0)
            .map(item => item.subSources)
            .flat();
      }
      let services = servicesData.data.map(obj => ({...obj, checked: false}))
      if (this.$store.getters['User/getFilters']) {
        this.filters = this.$store.getters['User/getFilters'];
      }
      const referralData = await referralClient.get('/Lead', { params: {
        dateTo: this.todaysDate,
        page: 1,
        perPage:10,
        ...this.filters
      },  paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})});
      const ethnicities = await referralClient.get('/Reference/ethnicity')
      const goals = await referralClient.get('/Reference/goals')
      let newGoals = goals.data.map(obj => ({...obj, checked: false}))
      await this.getCounts();

      const alcoholFreqOptions = await referralClient.get('/Reference/alcoholfrequency');
      const alcoholDailyCount = await referralClient.get('/Reference/dailycountalcohol');

      this.alcoholFrequencyList = alcoholFreqOptions.data;
      this.dailyAlcoholList = alcoholDailyCount.data;

      this.services = services;
      this.referralServicesList = referralServices.data;
      this.$store.commit('User/setEthnicityList', ethnicities.data);
      this.goals = newGoals;
      this.ethnicities = ethnicities.data;
      this.referralData = referralData.data.items;
      this.paginationInfo.totalCount = referralData.data.paginationInfo.count;
      this.paginationInfo.page = referralData.data.paginationInfo.page;
      this.paginationInfo.perPage = referralData.data.paginationInfo.perPage;

      if (this.$store.getters['User/getUserPreferences'].weight === '' || this.$store.getters['User/getUserPreferences'].height === '') {
        this.$store.commit('User/setUserPreferences', { weight: 'stlbs', height: 'ftin'})
      }
      this.isLoadingReferrals = false;
  },
  methods: {
    deleteLead() {
      referralClient.delete(`Lead/${this.tempStoreUserDeleting.id}`)
          .then((res) => {
            this.referralData.splice(this.tempStoreUserDeleting.index, 1);
            this.$toast.success('User deleted')
            this.tempStoreUserDeleting.id = null;
            this.tempStoreUserDeleting.index = null;
          })
          .catch((ex) => {
            console.log(ex);
          })
      this.isDeletingPatient = false;
    },
    openDeleteModal(id, index) {
      this.tempStoreUserDeleting.id = id;
      this.tempStoreUserDeleting.index = index;
      setTimeout(() => {
        this.isDeletingPatient = true;
      }, 50);
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    async getCounts() {
      const counts = await referralClient.get('/Lead/counts')
      this.counts = counts.data;
    },
    goToPatient(id) {
      this.$router.push({
        name: 'portal.referrals.id',
        params: {
          id: id
        }
      });
    },
    openAddPatientModal() {
      setTimeout(() => {
        this.isAddingPatient = true;
      }, 50);
    },
    openFilterListModal() {
      setTimeout(() => {
        this.isAddingFilters = true;
      }, 50);
    },
    onPageChanged(page) {
      this.paginationInfo.page = page;
      let params;
      this.isLoadingData = true;
      if (this.searchInput) {
        params = {
          name: this.searchInput,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: page,
          perPage: 10
        }
      } else {
        if (this.filters) {
          params = {
            ...this.filters ? this.filters : '',
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            page: page,
            perPage: 10,
          }
        } else {
          params = {
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            page: page,
            perPage: 10,
          }
        }
      }




      referralClient.get(`/Lead`, {params: {...params}, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})})
        .then((res) => {
          this.referralData = res.data.items;

          this.paginationInfo.totalCount = res.data.paginationInfo.count;
          this.paginationInfo.page = res.data.paginationInfo.page;
          this.paginationInfo.perPage = res.data.paginationInfo.perPage;
          this.isLoadingData = false;
        })
    },
    searchBarLookup() {
      this.isLoadingData = true;
      referralClient.get(`Lead`, {params: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          name: this.searchInput,
        }})
          .then((res) => {
            this.referralData = res.data.items;

            this.paginationInfo.totalCount = res.data.paginationInfo.count;
            this.paginationInfo.page = res.data.paginationInfo.page;
            this.paginationInfo.perPage = res.data.paginationInfo.perPage;
            this.isLoadingData = false;
          })
    },
    addNewPatient(val) {
      if (val === true) {
        this.isLoadingData = true;
        this.isAddingPatient = false;
        setTimeout(async () => {
          try {
            this.dateTo = new Date(new Date(this.dateTo).setHours(23,59,59,999));
            let referralData = await referralClient.get('/lead', {params: {
                dateTo: this.dateTo,
                page: 1,
                perPage:10,
              }});
            this.referralData = referralData.data.items;
            this.paginationInfo.totalCount = referralData.data.paginationInfo.count;
            this.paginationInfo.page = referralData.data.paginationInfo.page;
            this.paginationInfo.perPage = referralData.data.paginationInfo.perPage;
            this.isLoadingData = false;
          }
          catch(ex) {
            if(ex.response.status === 500) {
            }
          }
        }, 1000);
      }
    },
    async loadData(isReset) {
      if (this.searchInput) {
        return;
      }
      this.isLoadingData = true;
      let params;

      if (isReset === true) {
        this.$store.commit('User/setFilters', null);
        this.referralSources.forEach((item) => {
          if (item.checked === true) {
            item.checked = false;
          }
        })
        this.referralServicesList.forEach((item) => {
          if (item.checked === true) {
            item.checked = false;
          }
        })
        this.services.forEach((item) => {
          if (item.checked === true) {
            item.checked = false;
          }
        })
        this.dateFrom = null;
        this.filters = {
          dateTo: this.dateTo,
          page: 1,
          perPage: 10,
        };
      } else {
        this.filters = {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: 1,
          perPage: 10,
          ...this.filters
        };
      }

      if (this.dateFrom !== null) {
        this.dateFrom = new Date(new Date(this.dateFrom).setHours(0,0,0,0))
      }

      if (this.dateTo !== moment().toDate()) {
        this.dateTo = new Date(new Date(this.dateTo).setHours(23,59,59,999));
      }
      if (this.filters) {
        let mainParams = {
          ...this.filters
        }

        params = {
         ...this.filters  ? this.filters : '',
        }
      } else {
        params = {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: 1,
          perPage: 10,
        }
      }

      let customDateData = await referralClient.get('/Lead', {params: {...params}, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})});
      this.referralData = customDateData.data.items;

      this.paginationInfo.totalCount = customDateData.data.paginationInfo.count;
      this.paginationInfo.page = customDateData.data.paginationInfo.page;
      this.paginationInfo.perPage = customDateData.data.paginationInfo.perPage;
      this.isLoadingData = false;
    },
    filterAlphabetically() {
      this.filterFirstName = !this.filterFirstName;

      if (this.filterFirstName) {
        this.referralData.sort((a, b) => a.name.split(/\s(.+)/)[0].localeCompare(b.name.split(/\s(.+)/)[0]))

      } else {
        this.referralData.sort((a, b) => a.name.split(/\s(.+)/)[0].localeCompare(b.name.split(/\s(.+)/)[0])).reverse();
      }
    },
    filterData(config) {
      this.filters = config;

      referralClient.get('/Lead', { params: { ...this.filters },  paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}) })
          .then((res) => {
            this.isAddingFilters = false;
            this.referralData = res.data.items;

            this.paginationInfo.totalCount = res.data.paginationInfo.count;
            this.paginationInfo.page = res.data.paginationInfo.page;
            this.paginationInfo.perPage = res.data.paginationInfo.perPage;
          })
    },
    exportData() {
      this.isLoadingExport = true;
      let params;

      if (this.filters) {
        params = {
          ...this.filters  ? this.filters : '',
        }
      } else {
        params = {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        }
      }


      referralClient.get('/Lead/ExportSummary', { params, responseType: 'blob', paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}) })
      .then((res) => {
        const anchor = document.createElement('a');
        let blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        anchor.href = window.URL.createObjectURL(blob);
        anchor.target = '_blank';
        anchor.download = 'ReferralData.xlsx';
        anchor.click();
        this.isLoadingExport = false;
      })
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },
    confirmCloseFilters() {
      this.$deleteAccept({
        title: "About to close filter list.",
        confirmMessageTemplate: "Are you sure?",
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        animation: {
          confirmAnimationLeaveClass: 'zoomOut',
          confirmAnimationEnterClass: 'zoomIn'
        }
      })
          .then(() => {
            this.isAddingFilters = false;
          })
          .catch(() => {
            console.log('Cancelled');
          })
    },
    confirmCloseAddPatient() {
      this.$deleteAccept({
        title: "About to close adding new patient.",
        confirmMessageTemplate: "Are you sure?",
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        animation: {
          confirmAnimationLeaveClass: 'zoomOut',
          confirmAnimationEnterClass: 'zoomIn'
        }
      })
          .then(() => {
            this.isAddingPatient = false;
          })
          .catch(() => {
            console.log('Cancelled');
          })
    },
    returnCompletionString(completeVal) {
      if (completeVal === 'Complete') {
        return 'Yes'
      } else if (completeVal === 'NotComplete') {
        return 'No'
      } else {
        return completeVal;
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    openUploadModal() {
      setTimeout(() => {
        this.isAddingFile = true;
      }, 1);
    },
    closeImportModal() {
      setTimeout(() => {
        this.loadData()
        this.isAddingFile = false;
      }, 50);
    },
  },
  computed: {
    totalPageCount() {
      return Math.ceil(this.paginationInfo.totalCount/10)
    },
    pastDays(){
      let dateTo = this.dateTo ? this.dateTo : moment();
      return moment(dateTo).utc().diff(this.dateFrom, 'days')
    },
    filtersLength() {
      if (this.filters) {
       return Object.keys(this.filters).length;
      } else { return 0;}
    },
    isSuperUser() {
      let claims = store.getters['Auth/getClaims'];
      return ['super'].some(x => claims.role.includes(x));
    }
  }
}
</script>
<style lang="scss">
.spinner__wrap {
  text-align: center;
  .gro__spinner {
    color: #0278f8;
  }
}
  .referral-card {  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.19), 0 0 1px 0 rgba(0, 0, 0, 0.04); }
  .scrollable { height: 90%!important; display: block!important; }
  .gro__button {
    &:hover {
      svg {
        path {
          fill: #FFF;
        }
      }
    }
  }
  .container {
    .file_uploader {
      border-radius: 12px;
      border: dashed 1px #bfbfbf;
      padding: 10px;
      span {
        color: #7a7a7a;
      }
      .delete {
        color: #ff394a;
      }
    }
    .clearDates {
      color: #0078f8;
      cursor: pointer;
      span {
        text-decoration: underline;
        font-size: 1rem;
      }
    }
    .icon-info-circle {
      color: #c8c7cc;
    }
    .enrolled {
      .number {
        font-size: 28px;
        font-weight: bold;
      }
      .icon-info-circle {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .search-wrapper {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 1px solid #c8c7cc;
      font-family: Circular Std Book, San Francisco, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, sans-serif;
      font-family: var(--font-family);
      border-radius: 4px;
      font-size: .8888888889em;
      overflow: hidden;
      background: #fff;
      min-height: 44px;

    }
    .patients {
      .material-icons {
        font-size: 20px;
        vertical-align: sub;
      }
    }
    .searchBar {
      font-size: 1rem;
      input {
        border-radius: 8px;
        border: none;
        background-color: #fff;
      }
    }
    .patients-table {
      overflow: scroll;
      box-shadow: 0 1.3px 5px 0 rgba(0, 0, 0, 0.19), 0 0.3px 1.5px 0 rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      .gro__table {
        &:not(.is_condensed) tbody tr {
          border-bottom: 2px solid #ebebf0;
          &.duplicate {
            background-color: #fff7ed;
          }
        }
        &.is_condensed thead td {
          padding: 4px 0;
          padding-left: 0!Important;
        }
        tr {
          height: 64px;
        }
        thead {
          td {
            font-size: 14px;
            padding: 14px 12px 14px 0;
            &:first-child {
              padding-left: 12px;
            }
          }
        }
        tbody {
          tr {

            transition: background .2s ease-in-out;
            &:hover {
              cursor: pointer;
              background: #e9e9e9;
            }
          }
          td {
            border: none;
            font-size: 14px;
            .bmi {
              padding: 6px 9px;
              border-radius: 6px;
              font-weight: bold;
              font-size: 14px;
              &.green-bmi {
                background: #d4f8d3;
              }
              &.yellow-bmi {
                background: #fff0bb;
              }
              &.red-bmi {
                background: #ffe2e2;
              }
            }
            i {
              font-size: 14px;
            }
            .opacity-lower {opacity: .3;}
            .completed_icon {
              padding: 4px 4px 4px;
              color: #FFF;
              border-radius: 6px;
              i {
                font-size: 16px!important;
              }
              &.success {
                background: #4AC756;
              }
              &.fail {
                background: #B9B9B9;
              }
            }
          }
        }
        .table-id {
          font-size: 12px;
          line-height: 1.33;
          color: #707070;
        }
      }
    }
  }
  .tooltip-inner {
    background: #12344A!important;
  }
  .tooltip-arrow {
    border-color: #12344A!important;
   }
</style>